<template>
  <v-dialog
    v-model="show"
    @click:outside="$emit('close')"
    width="50vw"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="headline primary white--text">
        {{ kind }} game
      </v-card-title>
      <v-card-subtitle class="primary white--text">
        {{ labels.deployDialog }}
      </v-card-subtitle>
      <v-card-text class="pt-6">
        <v-col class="pa-0">
          <v-autocomplete
            v-model="selectedBE"
            auto-select-first
            clearable outlined
            :items="backendVersions"
            item-text="tag"
            :label=labels.selectBackend
            :prepend-icon="'$backend'"
          />
          <v-alert v-show="!selectedBE" text type="warning">
            {{ labels.deployDialogBack }}
          </v-alert>
        </v-col>
        <v-col class="pa-0">
          <v-autocomplete
            v-model="selectedFE"
            :items="frontendVersions"
            item-text="version"
            auto-select-first
            clearable outlined
            :label=labels.selectFrontend
            :prepend-icon="'$frontend'"
          />
          <v-alert text type="info">
            {{ labels.deployDialogFront }}
          </v-alert>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('close')">
          {{ labels.close }}
        </v-btn>
        <v-btn
          @click.prevent="$emit('confirm')"
          color="primary"
          text
          :disabled="!selectedBackend"
        >
          {{ labels.confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { find, get } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'deployDialog',
  props: {
    kind: {
      type: String,
      required: false,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'backendVersions',
      'frontendVersions',
      'permissions',
    ]),
    ...mapState('shared', [
      'loading',
      'refresh',
      'selectedBackend',
      'selectedFE',
    ]),
    selectedBE: {
      get() {
        return this.selectedBackend;
      },
      set(value) {
        this.selectBackend(value);
      },
    },
    selectedFE: {
      get() {
        return this.selectedFrontend;
      },
      set(value) {
        this.selectFrontend(value);
      },
    },
  },
  data: () => ({
    labels,
    provider: null,
  }),
  methods: {
    ...mapActions({
      selectBackend: 'shared/selectBackend',
      selectFrontend: 'shared/selectFrontend',
    }),
  },
};
</script>
